import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { GET_PAGE_REQUEST } from '../actions/actionTypes';

import {
  getPageSuccess,
  getPageFailure,
} from '../actions/pageActions';

import { getPageService } from '../services/pages';
import { getMenuRequestFlow } from './menuSagas';

export const getMenuData = state => state.menu.slugToInfo;
export const getPages = state => state.page.data;

export function* getPageRequestFlow({ slug }) {
  try {
    let menuData = yield select(getMenuData);
    if (!Object.keys(menuData).length) {
      yield call(getMenuRequestFlow, { slug: 'main-menu' });
      menuData = yield select(getMenuData);
    }
    const { postType, postId } = menuData[slug];

    const pages = yield select(getPages);
    if (pages[slug]) {
      return;
    }

    const response = yield call(getPageService, postType, postId);
    yield put(getPageSuccess(slug, response.data));
  } catch (error) {
    yield put(getPageFailure(error));
  }
}

export function* watchPageFlow() {
  yield all([
    takeLatest(GET_PAGE_REQUEST, getPageRequestFlow),
  ]);
}

const pageSagas = [watchPageFlow()];

export default pageSagas;
