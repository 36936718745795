import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { GET_HEADER_REQUEST } from '../actions/actionTypes';

import {
  getHeaderSuccess,
  getHeaderFailure,
} from '../actions/appActions';

import { getHeaderDetails } from '../services/header';

export const getHeaderData = state => state.app.header;

export function* getHeaderRequestFlow() {
  try {
    const headerData = yield select(getHeaderData);
    if (Object.keys(headerData).length) {
      return;
    }

    const response = yield call(getHeaderDetails);
    yield put(getHeaderSuccess(response.data));
  } catch (error) {
    yield put(getHeaderFailure(error));
  }
}

export function* watchHeaderFlow() {
  yield all([
    takeLatest(GET_HEADER_REQUEST, getHeaderRequestFlow),
  ]);
}

const headerSagas = [watchHeaderFlow()];

export default headerSagas;
