import {
  GET_HOMEPAGE_REQUEST,
  GET_HOMEPAGE_SUCCESS,
  GET_HOMEPAGE_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  data: null,
  isFetching: false,
  failed: false,
};

function homepageReducer(state = initialState, action) {
  switch (action.type) {

  case GET_HOMEPAGE_REQUEST:
    return {
      ...state,
      isFetching: true,
    };

  case GET_HOMEPAGE_SUCCESS:
    return {
      ...state,
      data: action.data,
      isFetching: false,
    };

  case GET_HOMEPAGE_FAILURE:
    return {
      ...state,
      isFetching: false,
      failed: true,
    };

  default:
    return state;
  }
}

export default homepageReducer;
