import {
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_FAILURE,
} from './actionTypes';

export function getMenuRequest(slug) {
  return { type: GET_MENU_REQUEST, slug };
}

export function getMenuSuccess(data) {
  return { type: GET_MENU_SUCCESS, data };
}

export function getMenuFailure() {
  return { type: GET_MENU_FAILURE };
}
