import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { classNames } from 'react-extras';
import { connect } from 'react-redux';
import obstruction from 'obstruction';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import Menu from './menu';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  toolbarWrapper: {
    maxWidth: 1190,
    margin: '0 auto',
    padding: 0,
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    minHeight: 64,
  },
  toolbarItem: {
    flexGrow: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    flex: 0,
    marginLeft: 0,

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  headerLink: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 42,
    marginRight: 6,
  },
  title: {
    margin: 0,
    flex: 0,
    whiteSpace: 'nowrap',
    marginLeft: 0,
    minWidth: 267,
    fontFamily: '"Oswald"',
    textTransform: 'uppercase',
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      marginLeft: 25,
    },

    '& a': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  menu: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      activeSubMenu: null,
      submenuClosing: false,
    };

    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.handleHoverOnMainMenu = this.handleHoverOnMainMenu.bind(this);
    this.hideSubMenu = this.hideSubMenu.bind(this);
  }

  onMenuButtonClick() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  handleHoverOnMainMenu(key) {
    this.setState({ activeSubMenu: key });
  }

  hideSubMenu(e) {
    if (e.metaKey) {
      return;
    }
    this.setState({ submenuClosing: true });
    setTimeout(() => {
      this.setState({ activeSubMenu: null, submenuClosing: false });
    }, 200);
  }

  render() {
    const { classes, header } = this.props;
    const { blogname, logo } = header;
    return (
      <AppBar position="fixed" className={classes.root} onMouseLeave={this.hideSubMenu}>
        <div className={classes.toolbarWrapper}>
          <Toolbar disableGutters className={classes.toolbar}>
            <IconButton className={classNames(classes.menuButton, classes.toolbarItem)} color="inherit" aria-label="Open drawer" onClick={this.onMenuButtonClick}>
              <MenuIcon />
            </IconButton>
            <Typography className={classNames(classes.title, classes.toolbarItem)} variant="h6" color="inherit">
              <Link to="/" onClick={this.hideSubMenu} className={classes.headerLink}>
                <img src={logo} alt="" className={classes.logo} />
                <span>{blogname}</span>
              </Link>
            </Typography>
            <div className={classNames(classes.menu, classes.toolbarItem)}>
              <Menu
                drawerOpen={this.state.drawerOpen}
                onCloseHandler={() => this.onMenuButtonClick()}
                activeSubMenu={this.state.activeSubMenu}
                submenuClosing={this.state.submenuClosing}
                onMainMenuHover={this.handleHoverOnMainMenu}
                hideSubMenu={this.hideSubMenu}
              />
            </div>
            <div className={classNames(classes.grow)} />
          </Toolbar>
        </div>
      </AppBar>
    );
  }
}

const mapStateToProps = obstruction({
  header: 'app.header',
});

export default withRouter(withStyles(styles)(connect(mapStateToProps)(Navigation)));
