import {
  CHANGE_SCREEN,
  SCREEN_LOADED,
  GET_HEADER_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  activeScreen: null,
  loading: false,
  header: {},
};

function appReducer(state = initialState, action) {
  switch (action.type) {

  case CHANGE_SCREEN:
    return {
      ...state,
      activeScreen: action.screen,
      loading: true,
    };

  case SCREEN_LOADED:
    return {
      ...state,
      loading: false,
    };

  case GET_HEADER_SUCCESS:
    return {
      ...state,
      header: action.data,
    };

  default:
    return state;
  }
}

export default appReducer;
