import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from './appReducer';
import homepage from './homepageReducer';
import menu from './menuReducer';
import page from './pageReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  app,
  homepage,
  menu,
  page,
});
