import {
  GET_PAGE_REQUEST,
  GET_PAGE_SUCCESS,
  GET_PAGE_FAILURE,
} from './actionTypes';

export function getPageRequest(slug) {
  return { type: GET_PAGE_REQUEST, slug };
}

export function getPageSuccess(slug, data) {
  return { type: GET_PAGE_SUCCESS, slug, data };
}

export function getPageFailure() {
  return { type: GET_PAGE_FAILURE };
}
