import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { GET_HOMEPAGE_REQUEST } from '../actions/actionTypes';

import { screenLoaded } from '../actions/appActions';
import {
  getHomepageSuccess,
  getHomepageFailure,
} from '../actions/homepageActions';

import { getHomepageDetails } from '../services/pages';

export const getHomepageData = state => state.homepage.data;

export function* getHomepageRequestFlow() {
  try {
    const homepageData = yield select(getHomepageData);
    if (homepageData) {
      yield put(screenLoaded());
      return;
    }

    const response = yield call(getHomepageDetails);
    yield put(getHomepageSuccess(response.data));
    yield put(screenLoaded());
  } catch (error) {
    yield put(getHomepageFailure(error));
  }
}

export function* watchHomepageFlow() {
  yield all([
    takeLatest(GET_HOMEPAGE_REQUEST, getHomepageRequestFlow),
  ]);
}

const homepageSagas = [watchHomepageFlow()];

export default homepageSagas;
