import React from 'react';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: props => props.height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: props => props.bgImage ? 'white' : '',
    overflow: 'hidden',
    backgroundColor: props => props.bgImage ? 'transparent' : 'white',

    '&::after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: -5,

      transform: props => `translateZ(-1px) scale(1.5) translateY(${props.offset}px)`,
      backgroundImage: props => props.bgImage ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.bgImage})` : '',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',

      zIndex: -1,
    },
  },
});

function Parallax(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      {props.children}
    </div>
  );
}

Parallax.defaultProps = {
  offset: 0,
  height: 750,
};

export default Parallax;
