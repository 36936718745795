import React from 'react';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import obstruction from 'obstruction';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const styles = {
  root: {
    background: 'white',
  },
  divider: {
    margin: '24px 0',
  },
};

function ContentPlaceholder(props) {
  const { classes, loading } = props;

  if (!loading) return [];

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={7} lg={8}>
          <ContentLoader width={752} height={340} style={{ height: '100%', width: '100%' }}>
            <rect x="0" y="0" rx="1" ry="1" width="100%" height="100%" />
          </ContentLoader>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <ContentLoader width={364} height={340}>
            <rect x="0" y="0" rx="1" ry="1" width="70%" height="26" />

            <rect x="0" y="60" rx="1" ry="1" width="85%" height="12" />
            <rect x="0" y="78" rx="1" ry="1" width="95%" height="12" />
            <rect x="0" y="96" rx="1" ry="1" width="90%" height="12" />
            <rect x="0" y="114" rx="1" ry="1" width="60%" height="12" />

            <rect x="0" y="168" rx="1" ry="1" width="150" height="40" />
          </ContentLoader>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container spacing={2}>
        {[1, 2, 3].map(item => (
          <Grid item xs={12} sm={4} key={item}>
            <ContentLoader width={364} height={408}>
              <rect x="0" y="0" rx="1" ry="1" width="100%" height="200" />

              <rect x="0" y="220" rx="1" ry="1" width="70%" height="26" />

              <rect x="0" y="280" rx="1" ry="1" width="85%" height="12" />
              <rect x="0" y="298" rx="1" ry="1" width="95%" height="12" />
              <rect x="0" y="316" rx="1" ry="1" width="60%" height="12" />

              <rect x="0" y="368" rx="1" ry="1" width="110" height="40" />
            </ContentLoader>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

const mapStateToProps = obstruction({
  loading: 'app.loading',
});

export default withStyles(styles)(connect(mapStateToProps)(ContentPlaceholder));
