import { all } from 'redux-saga/effects';

import headerSagas from './headerSagas';
import homepageSagas from './homepageSagas';
import menuSagas from './menuSagas';
import pageSagas from './pageSagas';

export default function* rootSaga() {
  yield all([
    ...headerSagas,
    ...homepageSagas,
    ...menuSagas,
    ...pageSagas,
  ]);
}
