export const CHANGE_SCREEN = 'CHANGE_SCREEN';
export const SCREEN_LOADED = 'SCREEN_LOADED';

export const GET_HEADER_REQUEST = 'GET_HEADER_REQUEST';
export const GET_HEADER_SUCCESS = 'GET_HEADER_SUCCESS';
export const GET_HEADER_FAILURE = 'GET_HEADER_FAILURE';

export const GET_HOMEPAGE_REQUEST = 'GET_HOMEPAGE_REQUEST';
export const GET_HOMEPAGE_SUCCESS = 'GET_HOMEPAGE_SUCCESS';
export const GET_HOMEPAGE_FAILURE = 'GET_HOMEPAGE_FAILURE';

export const GET_MENU_REQUEST = 'GET_MENU_REQUEST';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAILURE = 'GET_MENU_FAILURE';

export const GET_PAGE_REQUEST = 'GET_PAGE_REQUEST';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
export const GET_PAGE_FAILURE = 'GET_PAGE_FAILURE';
