import React from 'react';
import { classNames } from 'react-extras';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginTop: 50,
    minHeight: 270,
    backgroundColor: '#303a47',
    color: 'white',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 16px 30px',

    [theme.breakpoints.up('md')]: {
      padding: '25px 25px 40px',
    },
  },
  wrapper: {
    maxWidth: 1140,
    margin: '0 auto',
    padding: 0,
    width: '100%',
  },
  fullWidth: {
    marginTop: 0,
    textAlign: 'center',
  },
});

function Footer(props) {
  const { classes } = props;

  return (
    <div className={classNames(classes.root, classes.fullWidth)}>
      <div className={classes.wrapper}>
        <p>Metropolitan Pharmaceutical Products, Inc.</p>
        <p>
          15F, The Paragon Corporate Center<br />
          Industry St., Madrigal Business Park, Ayala Alabang<br />
          Muntinlupa City, Philippines
        </p>
      </div>
    </div>
  );
}

export default withStyles(styles)(Footer);
