import {
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_FAILURE,
} from '../actions/actionTypes';

import { apiBaseUrl } from '../config';
import { removeLeadingTrailingSlash } from '../utils';

const initialState = {
  data: {
    items: [],
  },
  isFetching: false,
  failed: false,
  slugToInfo: {},
};

function menuReducer(state = initialState, action) {
  switch (action.type) {

  case GET_MENU_REQUEST:
    return {
      ...state,
      isFetching: true,
    };

  case GET_MENU_SUCCESS: {
    const slugToInfo = {};
    Object.keys(action.data.items).map((key) => {
      const slug = removeLeadingTrailingSlash(action.data.items[key].url.replace(apiBaseUrl, ''));
      slugToInfo[slug] = {
        postType: action.data.items[key].object,
        postId: action.data.items[key].object_id,
      };
      if (!action.data.items[key].child_items) return null;
      action.data.items[key].child_items.map((child) => {
        const childSlug = removeLeadingTrailingSlash(child.url.replace(apiBaseUrl, ''));
        slugToInfo[childSlug] = {
          postType: child.object,
          postId: child.object_id,
        };
        return null;
      });
      return null;
    });
    return {
      ...state,
      data: action.data,
      isFetching: false,
      slugToInfo,
    };
  }

  case GET_MENU_FAILURE:
    return {
      ...state,
      isFetching: false,
      failed: true,
    };

  default:
    return state;
  }
}

export default menuReducer;
