import React, { Component } from 'react';
import { connect } from 'react-redux';
import { classNames } from 'react-extras';
import HttpsRedirect from 'react-https-redirect';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import obstruction from 'obstruction';

import { withStyles } from '@material-ui/core/styles';

import { getHeaderRequest } from './actions/appActions';
import { ContentPlaceholder, Footer, Navigation } from './components';
import {
  Home2, Page,
} from './screens';

const styles = theme => ({
  root: {
    minHeight: '100vh',
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    padding: '0 16px',
    flex: 1,

    [theme.breakpoints.up('md')]: {
      padding: '0 25px',
    },
  },
  pages: {
    background: 'white',
  },
  mainWrapper: {
    maxWidth: 1140,
    margin: '0 auto',
    paddingTop: 80,

    [theme.breakpoints.up('md')]: {
      paddingBottom: 40,
      paddingTop: 100,
    },
  },
  fullWidthMain: {
    padding: 0,
  },
  fullWidthMainWrapper: {
    maxWidth: 'none',
    padding: 0,
  },
});

class App extends Component {
  componentDidMount() {
    this.props.getHeaderRequest();
  }

  render() {
    const { classes, location, header } = this.props;
    const { blogname, blogdescription } = header;
    const title = blogdescription ? `${blogname} | ${blogdescription}` : blogname;
    return (
      <HttpsRedirect disabled>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Navigation />
        <div className={classNames(classes.root, classes.fullWidthRoot)}>
          <div className={classNames(classes.main, { [classes.pages]: location.pathname !== '/' }, classes.fullWidthMain)}>
            <div className={classNames(classes.mainWrapper, classes.fullWidthMainWrapper)}>
              <ContentPlaceholder />
              <Switch>
                <Route exact path="/" render={ownProps => <Home2 {...ownProps} />} />
                <Route exact render={ownProps => <Page {...ownProps} />} />
              </Switch>
            </div>
          </div>
          {location.pathname !== '/' && <Footer />}
        </div>
      </HttpsRedirect>
    );
  }
}

const mapStateToProps = obstruction({
  header: 'app.header',
});

const mapDispatchToProps = {
  getHeaderRequest,
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App)));
