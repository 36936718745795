import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { GET_MENU_REQUEST } from '../actions/actionTypes';

import {
  getMenuSuccess,
  getMenuFailure,
} from '../actions/menuActions';

import { getMenuService } from '../services/menus';

export const getMenuData = state => state.menu.data;

export function* getMenuRequestFlow({ slug }) {
  try {
    const menuData = yield select(getMenuData);
    if (Object.keys(menuData.items).length) {
      return;
    }

    const response = yield call(getMenuService, slug);
    yield put(getMenuSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getMenuFailure(error));
  }
}

export function* watchMenuFlow() {
  yield all([
    takeLatest(GET_MENU_REQUEST, getMenuRequestFlow),
  ]);
}

const menuSagas = [watchMenuFlow()];

export default menuSagas;
