import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { classNames } from 'react-extras';
import { Helmet } from 'react-helmet';
import ContentLoader from 'react-content-loader';
import obstruction from 'obstruction';
import ReactGA from 'react-ga';

import { withStyles } from '@material-ui/core/styles';

import { screenLoaded } from '../actions/appActions';
import { getPageRequest } from '../actions/pageActions';
import { removeLeadingTrailingSlash } from '../utils';
import { googleAnalyticsTrackingId } from '../config';

const styles = theme => ({
  root: {
    background: 'white',
    marginTop: 64,
    marginBottom: 40,

    '& > :last-child': {
      paddingBottom: 60,
    },
  },
  container: {
    padding: '0 16px',

    [theme.breakpoints.up('md')]: {
      paddingLeft: 25,
      paddingRight: 25,
    },
  },
  wrapper: {
    maxWidth: 1140,
    margin: '0 auto',
  },
  pageTitle: {
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: '#f8f8f8',
    marginBottom: 30,

    '& h1': {
      margin: 0,
      fontFamily: '"Lato"',
      textTransform: 'uppercase',
      fontSize: 25,
    },
  },
  pageContent: {
    maxWidth: '50em',
  },
});

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slug: null,
    };
  }

  componentDidMount() {
    const { location, menuIsFetching, menu } = this.props;
    const { pathname } = location;
    const slug = pathname.slice(1, -1);

    this.setState({ slug });

    if (!menuIsFetching && Object.keys(menu.items).length) {
      this.props.getPageRequest(slug);
      this.trackPage(pathname);
    }

    this.props.screenLoaded();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const { pathname } = location;
    const slug = removeLeadingTrailingSlash(pathname);
    if ((prevProps.menuIsFetching && !this.props.menuIsFetching) || (prevState.slug !== slug)) {
      this.props.getPageRequest(slug);
      this.trackPage(pathname);
    }
  }

  trackPage(page) {
    if (googleAnalyticsTrackingId) {
      ReactGA.pageview(page);
    }
  }

  render() {
    const { classes, location, pages, header } = this.props;
    const { pathname } = location;
    const slug = removeLeadingTrailingSlash(pathname);

    const data = pages[slug];

    const { blogname, blogdescription } = header;
    const title = blogdescription ? `${blogname} | ${blogdescription}` : blogname;
    const pageTitle = data ? `${data.title.rendered} | ${title}` : title;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <div className={classNames(classes.container, classes.pageTitle)}>
          <div className={classes.wrapper}>
            <div className={classes.pageContent}>
              <h1>{data ? data.title.rendered : <span>&nbsp;</span>}</h1>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes.pageContent}>
              {!data ? (
                <ContentLoader width={364} height={188}>
                  <rect x="0" y="0" rx="1" ry="1" width="70%" height="26" />
                  <rect x="0" y="60" rx="1" ry="1" width="85%" height="12" />
                  <rect x="0" y="78" rx="1" ry="1" width="95%" height="12" />
                  <rect x="0" y="96" rx="1" ry="1" width="60%" height="12" />
                </ContentLoader>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = obstruction({
  data: 'page.data',
  failed: 'page.failed',
  menuIsFetching: 'menu.isFetching',
  menu: 'menu.data',
  pages: 'page.data',
  header: 'app.header',
});

const mapDispatchToProps = {
  screenLoaded,
  getPageRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Page)));
