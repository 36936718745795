import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import obstruction from 'obstruction';
import ReactGA from 'react-ga';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { changeScreen } from '../actions/appActions';
import { getHomepageRequest } from '../actions/homepageActions';

import { Parallax } from '../components';
import { googleAnalyticsTrackingId } from '../config';

const styles = {
  root: {
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    perspective: '2px',
    WebkitBackfaceVisibility: 'hidden',
    backfaceVisibility: 'hidden',

    '& > div:first-child > div': {
      marginTop: 32,
      marginBottom: 0,
    },

    '& > div:last-child:after': {
      bottom: -90,
    },
  },
  divider: {
    margin: '24px 0',
    backgroundColor: 'rgba(255, 255, 255, 0.45)',
  },
  dividerNoBg: {
    margin: '24px 0',
  },
  card: {
    height: '100%',
    position: 'relative',
    paddingBottom: 32,
  },
  cardBody: {
    marginBottom: 24,
  },
  button: {
    '& a': {
      color: 'white',
      padding: '5px 16px',
    },
    borderColor: 'white',
    borderRadius: 0,
    padding: 0,
  },
  buttonNoBg: {
    '& a': {
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '5px 16px',
    },
    borderColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 0,
    padding: 0,
  },
  heroButton: {
    marginTop: 12,
  },
  inside: {
    width: 550,
    marginBottom: 30,
    padding: '0 12px',
    textAlign: 'center',
  },
};

class Home2 extends Component {
  componentDidMount() {
    this.props.getHomepageRequest();
    const htmlElement = document.documentElement;
    const className = 'is-home';
    if (htmlElement.classList) {
      htmlElement.classList.add(className);
    } else {
      htmlElement.className += ' ' + className;
    }
    const { location } = this.props;
    const { pathname } = location;
    this.trackPage(pathname);
  }

  componentWillUnmount() {
    const htmlElement = document.documentElement;
    const className = 'is-home';
    if (htmlElement.classList) {
      htmlElement.classList.remove(className);
    } else {
      htmlElement.className = htmlElement.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  trackPage(page) {
    if (googleAnalyticsTrackingId) {
      ReactGA.pageview(page);
    }
  }

  render() {
    const { classes, data } = this.props;

    if (!data) {
      return [];
    }

    const items = ['hero', 'team', 'companies', 'products'];
    data['hero-image'] = process.env.REACT_APP_HERO_IMAGE;

    return (
      <div className={classes.root}>
        {items.map((item, index) => (
          <Parallax bgImage={index % 2 === 0 && data[`${item}-image`]} key={item} height={index % 2 === 0 ? 500 : 400}>
            <div className={classes.inside}>
              <Typography variant="h5" component="h2" color={index % 2 === 0 ? 'inherit' : undefined}>
                {item === 'hero' ? 'Metropolitan Pharmaceutical Products, Inc.' : data[`${item}-title`]}
              </Typography>
              <Divider className={index % 2 === 0 ? classes.divider : classes.dividerNoBg} />
              <Typography component="p" className={classes.cardBody} color={index % 2 === 0 ? 'inherit' : undefined}>
                <span dangerouslySetInnerHTML={{ __html: data[`${item}-description`] }} />
              </Typography>
              <Button
                variant="outlined"
                className={index % 2 === 0 ? classes.button : classes.buttonNoBg}
              >
                <Link to={data[`${item}-button-route`]}>{data[`${item}-button-label`]}</Link>
              </Button>
            </div>
          </Parallax>
        ))}
        <Parallax bgImage={process.env.REACT_APP_FOOTER_IMAGE} height={500}>
          <div className={classes.inside}>
            <p>Metropolitan Pharmaceutical Products, Inc.</p>
            <p>
              15th Flr., The Paragon Corporate Centre<br />
              Industry Street, Madrigal Business Park<br />
              Ayala Alabang, Muntinlupa City 1780
            </p>
          </div>
        </Parallax>
      </div>
    );
  }
}

const mapStateToProps = obstruction({
  data: 'homepage.data',
  isFetching: 'homepage.isFetching',
  failed: 'homepage.failed',
});

const mapDispatchToProps = {
  changeScreen,
  getHomepageRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home2)));
