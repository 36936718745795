import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga';

import store, { history } from './store';
import './index.css';
import App from './App';
import { ScrollToTop } from './components';
import { googleAnalyticsTrackingId } from './config';
import * as serviceWorker from './serviceWorker';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const BASE_FONT_STACK = '"Lato", Helvetica, Arial, sans-serif';
const HEADER_FONT_STACK = '"Oswald", "Lato", Helvetica, Arial, sans-serif';

const THEME = createMuiTheme({
  typography: {
    fontFamily: BASE_FONT_STACK,
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    useNextVariants: true,

    h1: {
      fontFamily: HEADER_FONT_STACK,
    },
    h2: {
      paddingTop: 71,
      marginBottom: '0.35em',
      fontFamily: HEADER_FONT_STACK,
    },
    h3: {
      fontFamily: HEADER_FONT_STACK,
    },
    h4: {
      fontFamily: HEADER_FONT_STACK,
    },
    h5: {
      fontFamily: HEADER_FONT_STACK,
    },
    h6: {
      marginTop: 20,
      fontFamily: HEADER_FONT_STACK,
    },
    body2: {
      marginTop: '1.5em',
      marginBottom: '0.5em',
    },
  },
  breakpoints: {
    values: {
      sm: 600,
      md: 768,
      lg: 960,
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={THEME}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </ConnectedRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (googleAnalyticsTrackingId) {
  ReactGA.initialize(googleAnalyticsTrackingId);
}
