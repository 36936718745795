import React, { Component, Fragment } from 'react';
import { classNames } from 'react-extras';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import obstruction from 'obstruction';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { getMenuRequest } from '../actions/menuActions';

import { apiBaseUrl } from '../config';
import { removeLeadingTrailingSlash } from '../utils';

const styles = theme => ({
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: '0 0 0 16px',
    display: 'flex',
  },
  listItem: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('background-color'),
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: 'rgba(211, 211, 211, 0.2)',
    },
  },
  link: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 8,
  },
  sidebar: {
    width: 250,
  },
  me: {
    color: 'white',
    backgroundColor: '#303a47',
    height: 250,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '0 16px',
    textAlign: 'left',
    fontFamily: '"Oswald"',
    fontSize: '2.2rem',
    fontWeight: 400,
    lineHeight: '2.7rem',
    textTransform: 'uppercase',
  },
  sidebarList: {
    padding: 0,
  },
  sidebarItem: {
    padding: 0,

    '& a': {
      padding: '8px 16px',
      display: 'block',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '15px',
    },
  },
  sidebarItemText: {
    textAlign: 'left',
  },
  sidebarSubMenuItem: {
    padding: 0,

    '& a': {
      padding: '8px 16px 8px 48px',
      display: 'block',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '15px',
    },
  },
  selectedTopbar: {
    backgroundColor: 'rgba(0, 255, 255, 0.1)',
  },
  selectedSidebar: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  subMenu: {
    display: 'none',
    position: 'fixed',
    width: '100%',
    left: 0,
    background: '#fafafa',
    marginTop: 15,
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    paddingBottom: 30,
    transition: '200ms opacity ease-out',
    opacity: 1,
  },
  subMenuOpen: {
    display: 'block',
  },
  subMenuClosing: {
    pointerEvents: 'none',
    opacity: 0,
  },
  subMenuWrapper: {
    maxWidth: 1190,
    padding: '0 25px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  subMenuPanel: {
    width: `${267 + 16}px`,
    marginTop: 30,
    fontSize: 32,
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: '"Oswald"',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  subMenuList: {
    marginTop: 38,
    padding: 0,
    listStyleType: 'none',

    '& a': {
      color: 'rgba(0, 0, 0, 0.87)',
      padding: 8,
      display: 'inline-block',

      '&:hover': {
        backgroundColor: 'rgba(211, 211, 211, 0.2)',
      },
    },
  },
  closeSubMenuButton: {
    position: 'absolute',
    top: 25,
    right: 0,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  activePage: {
    fontWeight: 700,
  },
});

class Menu extends Component {
  constructor(props) {
    super(props);

    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  componentWillMount() {
    this.props.getMenuRequest('main-menu');
  }

  handleMouseOver(key) {
    this.props.onMainMenuHover(key);
  }

  render() {
    const {
      activeScreen, classes, drawerOpen, onCloseHandler, activeSubMenu, menu, location, submenuClosing,
    } = this.props;

    const { pathname } = location;
    const currentSlug = removeLeadingTrailingSlash(pathname);
    return (
      <Fragment>
        <nav>
          <ul className={classes.list}>
            {Object.keys(menu.items).map((key) => {
              const menuItem = menu.items[key];
              return (
                <li
                  key={menuItem.ID}
                  className={classNames(classes.listItem, { [classes.selectedTopbar]: activeScreen === menuItem.ID })}
                  onMouseOver={() => this.handleMouseOver(menuItem.ID)}
                >
                  <Link
                    to={menuItem.url.replace(apiBaseUrl, '')}
                    onClick={this.props.hideSubMenu}
                    className={classNames(classes.link, { [classes.activePage]: currentSlug === removeLeadingTrailingSlash(menuItem.url.replace(apiBaseUrl, '')) })}
                  >
                    {menuItem.title}
                  </Link>
                </li>
              );
            })}
          </ul>

          <Drawer open={drawerOpen} onClose={() => onCloseHandler()}>
            <div
              tabIndex={0}
              role="button"
              onClick={() => onCloseHandler()}
              onKeyDown={() => onCloseHandler()}
            >
              <div className={classes.sidebar}>
                <div className={classes.me}>
                  Metropolitan Pharma
                </div>
                <List className={classes.sidebarList}>
                  {Object.keys(menu.items).map((key) => {
                    const menuItem = menu.items[key];
                    const link = (
                      <Link
                        to={menuItem.url.replace(apiBaseUrl, '')}
                        className={classNames({ [classes.activePage]: currentSlug === removeLeadingTrailingSlash(menuItem.url.replace(apiBaseUrl, '')) })}
                      >
                        {menuItem.title}
                      </Link>
                    );
                    return (
                      <Fragment key={menuItem.ID}>
                        <ListItem button className={classNames(classes.sidebarItem, { [classes.selectedSidebar]: activeScreen === menuItem.ID })}>
                          <ListItemText primary={link} className={classes.sidebarItemText} />
                        </ListItem>
                        {menuItem.child_items && menuItem.child_items.length && (
                          <List className={classes.sidebarList}>
                            {menuItem.child_items.map((subMenuItem) => {
                              const subLink = (
                                <Link
                                  to={subMenuItem.url.replace(apiBaseUrl, '')}
                                  className={classNames({ [classes.activePage]: currentSlug === removeLeadingTrailingSlash(subMenuItem.url.replace(apiBaseUrl, '')) })}
                                >
                                  {subMenuItem.title}
                                </Link>
                              );
                              return (
                                <ListItem button key={subMenuItem.ID} className={classNames(classes.sidebarSubMenuItem, { [classes.selectedSidebar]: activeScreen === subMenuItem.ID })}>
                                  <ListItemText primary={subLink} className={classes.sidebarItemText} />
                                </ListItem>
                              );
                            })}
                          </List>
                        )}
                      </Fragment>
                    );
                  })}
                </List>
              </div>
            </div>
          </Drawer>
        </nav>

        {Object.keys(menu.items).map((key) => {
          const menuItem = menu.items[key];
          if (!menuItem.child_items) {
            return null;
          }
          return (
            <div key={menuItem.ID} className={classNames(classes.subMenu, { [classes.subMenuClosing]: submenuClosing }, { [classes.subMenuOpen]: activeSubMenu === menuItem.ID })}>
              <div className={classes.subMenuWrapper}>
                <div className={classes.subMenuPanel}>
                  {menuItem.title}
                </div>
                <ul className={classes.subMenuList}>
                  {menuItem.child_items.map(subMenuItem => (
                    <li key={subMenuItem.ID} className={classNames({ [classes.activePage]: currentSlug === removeLeadingTrailingSlash(subMenuItem.url.replace(apiBaseUrl, '')) })}>
                      <Link to={subMenuItem.url.replace(apiBaseUrl, '')} onClick={this.props.hideSubMenu}>{subMenuItem.title}</Link>
                    </li>
                  ))}
                </ul>
                <IconButton className={classes.closeSubMenuButton} color="inherit" aria-label="Close menu" onClick={this.props.hideSubMenu}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

const mapStateToProps = obstruction({
  activeScreen: 'app.activeScreen',
  menu: 'menu.data',
});

const mapDispatchToProps = {
  getMenuRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Menu)));
