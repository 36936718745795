import {
  GET_HOMEPAGE_REQUEST,
  GET_HOMEPAGE_SUCCESS,
  GET_HOMEPAGE_FAILURE,
} from './actionTypes';

export function getHomepageRequest() {
  return { type: GET_HOMEPAGE_REQUEST };
}

export function getHomepageSuccess(data) {
  return { type: GET_HOMEPAGE_SUCCESS, data };
}

export function getHomepageFailure() {
  return { type: GET_HOMEPAGE_FAILURE };
}
