import {
  GET_PAGE_REQUEST,
  GET_PAGE_SUCCESS,
  GET_PAGE_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  data: {},
  isFetching: false,
  failed: false,
};

function pageReducer(state = initialState, action) {
  switch (action.type) {

  case GET_PAGE_REQUEST:
    return {
      ...state,
      isFetching: true,
    };

  case GET_PAGE_SUCCESS: {
    const data = Object.assign({}, state.data, { [action.slug]: action.data });
    return {
      ...state,
      data,
      isFetching: false,
    };
  }

  case GET_PAGE_FAILURE:
    return {
      ...state,
      isFetching: false,
      failed: true,
    };

  default:
    return state;
  }
}

export default pageReducer;
